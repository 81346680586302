import React, { useEffect } from 'react';

import Layout from "../components/Layout/Layout";
import Policy from "../components/TermsPolicies/Policy";
import Hero from "../components/TermsPolicies/Hero";

import "../styles/pages/terms-policies.scss";

export default function TermsPolicies(props) {
  useEffect(() => {
    // Set cookie to expire in 365 days
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    document.cookie = `dismissedTermsToast=true; expires=${expiryDate.toUTCString()}; path=/`;
  }, []);

  return (
    <Layout>
      <Hero />
      <Policy />
    </Layout>
  );
}

import React from "react";
import { Link } from "gatsby";

export default function Policy() {
  return (
    <div className="policy-container">
      <div className="policy-box">
        <h2>Terms of Service</h2>
        <p>DeltaMath's Terms of Service govern the use of its online services and apply to all users, including school districts, schools, teachers, students, and parents.</p>
        <Link to="/terms-of-service" className="policy-link">Read Terms</Link>
      </div>

      <div className="policy-box">
        <h2>DeltaMath Privacy Policy</h2>
        <p>DeltaMath's Privacy Policy applies to educational providers using DeltaMath’s services, and ensures that Student Data is kept confidential. The collection, use, and sharing of this data are governed by agreements with the school or district, as well as relevant laws like FERPA and COPPA, and relevant state laws, including, but not limited to, SOPPA and New York State Ed Law 2-D.</p>
        <Link to="/privacy-policy" className="policy-link">Read Policy</Link>
      </div>

      <div className="policy-box">
        <h2>DeltaMath Children's Privacy Policy</h2>
        <p>DeltaMath's Children's Privacy Policy applies to Learner accounts associated with a Parent account. DeltaMath does not allow children under 13 to create accounts and does not collect personal information from them without parental consent.</p>
        <Link to="/childrens-privacy-policy" className="policy-link">Read Policy</Link>
      </div>
    </div>
  );
}
